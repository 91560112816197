import React from 'react';
import PropTypes from 'prop-types';
import { AdSense, GoogleCaf } from '../AdSense';
import { Logger } from '../../commons/Logger';
import {
  getSimple2CAds,
  getSimple2CLightAds,
  getSimple2CLightRelatedLinks,
  getSimple2CRelatedLinks,
  getSimple2CSearchbox,
  Simple2C,
  Simple2CExperiment,
  Simple2CMobileExperiment,
  Simple2CMobile,
  Simple2CLight,
  Simple2CLightMobile,
  getSimple2CLightPageLevel
} from './Simple2C';
import {
  getLightBlueAds,
  getLightBlueRelatedLinks,
  getLightBlueSearchbox,
  LightBlue,
  LightBlueMobile,
  getLightBluePageLevelParams
} from './LightBlue';
import {
  VerticalLines,
  VerticalLinesMobile,
  VerticalLinesBackground,
  getVertLinesSearchbox,
  getVertLinesPageLevelParams,
  getVertLinesRelatedLinks
} from './VerticalLines';
import {
  getParkWebPageLevel,
  getParkWebRelatedLinks, ParkWeb, ParkWebMobile
} from './ParkWeb';
import {
  getHegPageLevel,
  getHegRelatedLinks, Heg, HegMobile
} from './Heg';
import { isMobileBrowser } from '../../commons/HelperFunctions';


/**
 * Returns the stylesheet inside a style tag.
 * @param props
 * @returns {*}
 * @constructor
 */
export default function LayoutStyle(props) {
  const template = props.template;

  if (template === AdSense.template.SIMPLE_2C) {
    return isMobileBrowser() ? <Simple2CMobile/> : <Simple2C/>;
  } else if (template === AdSense.template.SIMPLE_2C_EXPERIMENT) {
    return isMobileBrowser() ? <Simple2CMobileExperiment /> : <Simple2CExperiment />;
  } else if (template === AdSense.template.SIMPLE_2C_LIGHT) {
    return isMobileBrowser() ? <Simple2CLightMobile /> : <Simple2CLight />;
  } else if (template === AdSense.template.LIGHT_BLUE) {
    return isMobileBrowser() ? <LightBlueMobile /> : <LightBlue />;
  } else if (template === AdSense.template.VERTICAL_LINES) {
    return isMobileBrowser() ? <VerticalLinesMobile /> : <VerticalLines />;
  } else if (template === AdSense.template.PARK_WEB) {
    return isMobileBrowser() ? <ParkWebMobile /> : <ParkWeb />;
  } else if (template === AdSense.template.PARK_WEB_HEG) {
    return isMobileBrowser() ? <HegMobile /> : <Heg />;
  }
}

LayoutStyle.propTypes = {
  template: PropTypes.string.isRequired
};

/**
 * @return {null}
 */
export function SimpleLayoutBackground(props, testMobile) {
  const template = props.template;
  if (template === AdSense.template.VERTICAL_LINES) {
    if (isMobileBrowser() || testMobile) {
      return null;
    }
    return <VerticalLinesBackground />;
  }
  return null;
}

SimpleLayoutBackground.propTypes = {
  template: PropTypes.string.isRequired
};

export function getSearchboxConfig(template) {
  return getConfig(template, GoogleCaf.blockType.SEARCH_BOX);
}

export function getRelatedLinksConfig(template) {
  return getConfig(template, GoogleCaf.blockType.RELATED_SEARCH);
}

export function getAdsConfig(template) {
  return getConfig(template, GoogleCaf.blockType.ADS);
}

// block type -> template -> function
const adsenseConfig = {
  searchbox: {
    SIMPLE_2C: getSimple2CSearchbox(),
    SIMPLE_2C_LIGHT: getSimple2CSearchbox(),
    LIGHT_BLUE: getLightBlueSearchbox(isMobileBrowser()),
    VERTICAL_LINES: getVertLinesSearchbox(),
    PARK_WEB: null,
    PARK_WEB_HEG: null
  },
  relatedsearch: {
    SIMPLE_2C: getSimple2CRelatedLinks(),
    SIMPLE_2C_LIGHT: getSimple2CLightRelatedLinks(),
    LIGHT_BLUE: getLightBlueRelatedLinks(isMobileBrowser()),
    VERTICAL_LINES: getVertLinesRelatedLinks(),
    PARK_WEB: getParkWebRelatedLinks(isMobileBrowser()),
    PARK_WEB_HEG: getHegRelatedLinks(isMobileBrowser())
  },
  ads: {
    SIMPLE_2C: getSimple2CAds(),
    SIMPLE_2C_LIGHT: getSimple2CLightAds(),
    LIGHT_BLUE: getLightBlueAds(),
    VERTICAL_LINES: null,
    PARK_WEB: null,
    PARK_WEB_HEG: null
  },
  pagelevel: {
    SIMPLE_2C: null,
    SIMPLE_2C_LIGHT: getSimple2CLightPageLevel(),
    LIGHT_BLUE: getLightBluePageLevelParams(),
    VERTICAL_LINES: getVertLinesPageLevelParams(),
    PARK_WEB: getParkWebPageLevel(),
    PARK_WEB_HEG: getHegPageLevel()
  }
};

function getConfig(template, type) {
  try {
    return adsenseConfig[type][template];
  } catch (e) {
    Logger.error('No case definition for block type ' + type + ', template ' + template);
  }
  return null;
}

// Page-Level Parameters as defined in the AdSense Custom Ad Frame (CAF) implementation guide and protocol reference
// These options apply to all the CAF blocks on the landing page.
export function getPageLevelParams(landerConfig) {
  return adsenseConfig.pagelevel[landerConfig.lander.template];
}
