import React, { Component } from 'react';
import { setupCallbackTracker } from '../AdSenseCallback';
import {
  AdSense,
  getDefaultAdsOptions,
  getDefaultPageOptions,
  getDefaultRelatedLinkOptions
} from '../AdSense';
import { getAdsConfig, getPageLevelParams, getRelatedLinksConfig } from './LayoutStyle';
import LayoutStyle from './LayoutStyle';
import { isBannerEnabled } from '../../banner/Banner';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import { Domain, GetDomainButton, Privacy } from './ParkWebLayout';
import { getParkWebHegBannerText } from '../../banner/Banner';
import { ReactComponent as Reglogo } from './123Reg.svg';

export default class HegLayout extends Component {

  componentDidMount() {

    const lc = this.props.landerConfig;
    const qc = this.props.queryConfig;
    const template = lc.lander.template;

    // Setup callback and tracking
    setupCallbackTracker(lc, qc);

    // Create the caf options:
    // Merge the template-specific options with the default settings.
    // Template-specific settings (right hand side of the spread operator) will override default options.

    const pageOptions = { ...getDefaultPageOptions(lc), ...getPageLevelParams(lc) };
    let pageContentOptions;

    // Page content - Related link or Sponsored listings
    if (this.props.page === AdSense.page.RELATED_LINKS) {
      pageContentOptions = { ...getDefaultRelatedLinkOptions(lc, qc), ...getRelatedLinksConfig(template) };
    } else {
      pageContentOptions = { ...getDefaultAdsOptions(lc, qc), ...getAdsConfig(template) };
    }

    // Call Google caf library
    if (typeof window.google !== 'undefined') {
      window.google.ads.domains.Caf(pageOptions, pageContentOptions);
    }

  }

  render() {

    const landerConfig = this.props.landerConfig;
    const page = this.props.page;
    const content = this.props.content;

    let adsenseContentDivId = 'ads';
    if (page === AdSense.page.RELATED_LINKS) {
      adsenseContentDivId = 'relatedLinks';
    }

    const domainContent = (isBannerEnabled(landerConfig) ?
      <div>
        <Domain landerConfig={ landerConfig } />
        <DomainInfo landerConfig={ landerConfig } content={ content } />
        <GetDomainButton landerConfig={ landerConfig } />
      </div> : null);

    return (
      <div id={ 'contentLayout' }>
        <LayoutStyle template={ landerConfig.lander.template } />
        <div id={ 'domainBanner' }>
          <div id={ 'headerLogoWrapper' }>
            <Reglogo />
          </div>

          <div id={ 'headerText' }>
            <div id={ 'headerTextTitle' }>
              {landerConfig && landerConfig.lander && landerConfig.lander.domainDisplayName}
            </div>
            {domainContent}
          </div>
          <div id={ 'headerFooter' }>
            {content.hegFooterText}
          </div>
        </div>
        <div id={ adsenseContentDivId }>
          <FormattedMessage id='adsBlockedText' />
        </div>
        <div id={ 'copyright' }>
          <FormattedMessage id='parkwebhegcopyrightText' values={{ currentYear: new Date().getFullYear() }} />
          <Privacy landerConfig={ landerConfig } />
        </div>
        <div id={ 'disclaimer' }>
          <FormattedMessage id='parkwebhegDisclaimerText'  />
        </div>
      </div>
    );
  }
}

/**
 * @return {null || html}
 */
export function DomainInfo({ landerConfig, content }) {
  const text = getParkWebHegBannerText(landerConfig, content);
  if (typeof (text) === 'undefined' || text === '') {
    return null;
  }
  return (
    <div id={ 'domainInfo' }>
      {text}
    </div>
  );
}

// queryConfig is a required parameter in getDefaultRelatedLinkOptions
HegLayout.propTypes = {
  page: PropTypes.string.isRequired,
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired
};
