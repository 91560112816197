/**
 * Commonly used constants.
 * Keep this file organized and small.
 */
import { parseBoolean } from './HelperFunctions';

export const Settings = {
  DOMAIN_CONFIG_API: process.env.REACT_APP_DOMAIN_CONFIG_API,
  PARKWEB_LANDER_API: process.env.REACT_APP_PARKWEB_LANDER_API,
  EVENT_PUBLISH_API: process.env.REACT_APP_EVENT_PUBLISH_API,
  GOOGLE_ADSENSE_ADTEST: process.env.REACT_APP_ADSENSE_ADTEST,
  DEBUG_MODE: parseBoolean(process.env.REACT_APP_DEBUG_MODE),
  VERIZON_FEED_PARTNER: process.env.REACT_APP_VERIZON_FEED_PARTNER,
  VERIZON_FEED_ENABLE: process.env.REACT_APP_VERIZON_FEED_ENABLE
};

// Verizon feed path

export const VERIZON_FEED_PATH = '/d/search/p/nameadmin/xml/domain/multiset/premiumlink/v4/'

// API request status
export const FetchStatus = {
  PENDING: 1, // API call has not being initiated or is in-progress
  SUCCESS: 2, // API call is completed and was successful
  FAILURE: 3 // API call is completed but encountered an error
};

export const SystemDefault = {
  PRIVACY_POLICY_URL: 'https://www.godaddy.com/agreements/showdoc?pageid=PRIVACY'
};

/**
 * Different display type (Ads, for-sale page)
 */
export const DisplayType = {
  ADS: 'ADS',
  FOR_SALE: 'FOR_SALE'
};

/**
 * Domain Status
 */
export const DomainStatus = {
  ACTIVE: 'ACTIVE',
  BLACKLISTED: 'BLACKLISTED',
  SUSPENDED_FRAUD: 'SUSPENDED_FRAUD',
  SUSPENDED_TRADEMARK: 'SUSPENDED_TRADEMARK'
};

/*
 * Events on Parking Lander application
 */
export const EventType = {
  AD_CLICK: 'AD_CLICK',
  BANNER_CLICK: 'BANNER_CLICK',
  ERROR: 'ERROR',
  KEYWORD_CLICK: 'KEYWORD_CLICK',
  KEYWORD_SEARCH: 'KEYWORD_SEARCH',
  OWS_CLICK: 'OWS_CLICK',
  REDIRECT_FOS: 'REDIRECT_FOS',
  VISIT: 'VISIT',
  VISIT_NO_ADS: 'VISIT_NO_ADS',
  CAF_BLOCKED: 'CAF_BLOCKED',
  ADSENSE_ERROR: 'ADSENSE_ERROR',
  ADS_BLOCKED: 'ADS_BLOCKED',
  YAHOO_VISIT: 'YAHOO_VISIT'
};

export const FeedProvider = {
  GOOGLE_AD_SENSE: 'GOOGLE_AD_SENSE'
};

/**
 * Supported query parameters (user-level).
 * All query param keys are lower-cased while parsing
 */
export const QueryParam = {
  DOMAIN: 'domain', // string: can be use to specify domain name
  S_TOKEN: 's_token', // string: tracking code
  UUID: 'uuid', // UUID sent by the caller
  TEMPLATE: 'template', // string: template to render
  SEARCH_BOX: 'searchbox', // boolean: Adsense search box show/hide
  SEARCH_BUTTON: 'searchbutton', // boolean: Adsense search bottom  show/hide
  SHOW_DOMAIN: 'showdomain', // boolean: show/hide
  TDFS: 'tdfs', // boolean: show/hide
  BACK_FILL: 'backfill', // boolean: Adsense backfill ads
  TERM: 'term', // array: Adsense related search terms
  DEBUG_MODE: 'debugmode' // boolean: If true then Logger severity is set to TRACE
};

/*
 * Application error codes
 */
export const ErrorCode = {
  ERROR_FETCHING_DOMAIN_CONFIG: 'ERROR_FETCHING_DOMAIN_CONFIG',
  ERROR_DOMAIN_STATUS_INVALID: 'ERROR_DOMAIN_STATUS_INVALID',
  ERROR_ADSENSE_STATUS_INVALID: 'ERROR_ADSENSE_STATUS_INVALID',
  ERROR_DRID_NOT_APPROVED: 'ERROR_DRID_NOT_APPROVED',
  ERROR_UNKNOWN_DOMAIN_TYPE: 'ERROR_UNKNOWN_DOMAIN_TYPE',
  ERROR_NO_DOMAIN: 'ERROR_NO_DOMAIN',
  ERROR_NO_DRID: 'ERROR_NO_DRID',
  ERROR_NO_CHANNEL: 'ERROR_NO_CHANNEL',
  ERROR_NO_PUBID: 'ERROR_NO_PUBID'
};

export const LanderCookie = {
  CITY: 'city',
  CLIENT_IP: 'caf_ipaddr',
  COUNTRY: 'country',
  EXPIRY_PARTNER: 'expiry_partner',
  SYSTEM: 'system',
  TRAFFIC_TARGET: 'traffic_target',
  VISITOR_ID: 'pvisitor'
};

export const System = {
  SMARTNAME: 'SN',
  CASHPARKING: 'CP',
  PARKWEB: 'PW'
};

export const Locale = {
  EN_US: 'en'
};
