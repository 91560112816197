import React from 'react';
import CssRule, { cssRule, cssRules } from '../../commons/CssRule';
import { GoogleCaf } from '../AdSense';

export function getVertLinesPageLevelParams() {
  return {
    styleId: GoogleCaf.styleId.VERTICAL_LINES
  };
}

export function getVertLinesSearchbox() {
  return {
    radiusSearchInputBorder: '5px', // Use this parameter to add rounded borders to the search input field AND the search button.
    heightSearchInput: '22px',
    heightSearchButton: '22px',
    fontSizeSearchInput: '12px',
    fontSizeSearchButton: '12px'
  };
}

export function getVertLinesRelatedLinks() {
  return {
    colorBackground: 'transparent',
    attributionSpacingBelow: '2',
    attributionUppercase: false,
    attributionBold: false,
    fontSizeAttribution: '11px',
    titleBold: false,
    fontSizeTitle: '24',
    lineHeightTitle: '40',
    noTitleUnderline: true,
    // Explicitly setting the default white arrow image provided by Google
    adIconUrl: 'https://www.gstatic.com/domainads/images/chevron-white.png',
    adIconHeight: '18', // Required (if adIconUrl is set)
    adIconWidth: '18', // Required (if adIconUrl is set)
    adIconSpacingAbove: '10',
    adIconSpacingAfter: '10',
    verticalSpacing: '2',
    adBorderSelections: 'bottom',
    rolloverLinkColor: '#5595E9',
    colorAttribution: '#2b2b2b',
    colorTitleLink: '#e08d20',
    colorAdBorder: '#525252',
    colorText: '#666666'
  };
}

export function VerticalLinesBackground() {
  return <div className='svg-container'>
    <svg className='polygons' viewBox={ '0 0 100 100' } preserveAspectRatio={ 'xMidYMid slice' }>
      <defs>
        <linearGradient id='grad1' x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='70%' stopColor='rgb(2,164,166)' stopOpacity='1' />
          <stop offset='100%' stopColor='rgb(5,138,147)' stopOpacity='1' />
        </linearGradient>
        <linearGradient id='grad2' x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='70%' stopColor='rgb(166,255,248)' stopOpacity='1' />
          <stop offset='100%' stopColor='rgb(156,237,231)' stopOpacity='1' />
        </linearGradient>
        <linearGradient id='grad3' x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='70%' stopColor='rgb(216,238,239)' stopOpacity='1' />
          <stop offset='100%' stopColor='rgb(204,225,226)' stopOpacity='1' />
        </linearGradient>
        <linearGradient id='grad4' x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='70%' stopColor='rgb(244,248,252)' stopOpacity='1' />
          <stop offset='100%' stopColor='rgb(228,231,233)' stopOpacity='1' />
        </linearGradient>
      </defs>
      <polygon fill='url(#grad1)' points='0,0 9,0 9,60 0,60' />
      <polygon fill='url(#grad2)' points='9,0 20,0 20,60 9,60'/>
      <polygon fill='url(#grad3)' points='20,0 31,0 31,60 20,60'/>
      <polygon fill='url(#grad4)' points='31,0 69,0 69,60 31,60'/>
      <polygon fill='url(#grad3)' points='69,0 80,0 80,60 69,60'/>
      <polygon fill='url(#grad2)' points='80,0 91,0 91,60 80,60'/>
      <polygon fill='url(#grad1)' points='91,0 100,0 100,60 91,60'/>

      <polygon className='polygon1' points='0,60 9,60 0,68'/>
      <polygon className='polygon2' points='9,60 20,60 0,86 0,68'/>
      <polygon className='polygon3' points='20,60 31,60 18,100 0,100 0,86'/>
      <polygon className='polygon4' points='31,60 69,60 82,100 18,100'/>
      <polygon className='polygon3' points='69,60 80,60 100,86 100,100 82,100'/>
      <polygon className='polygon2' points='80,60 91,60 100,68 100,86'/>
      <polygon className='polygon1' points='91,60 100,60 100,68'/>
    </svg>
  </div>;
}

export function VerticalLines() {
  return <style>
    {commonRules()}
    {cssRule('body', [
      'background-color: #EEF6FF;'
    ])}
    {cssRule('#contentLayout', [
      'width: 75%;',
      'min-width: 400px;',
      'max-width: 600px;',
      'padding: 10px;',
    ])}
    {cssRule('#domain', [
      'color:#999;'
    ])}
    {cssRule('#sponsored_listings', [
    ])}
    {cssRule('#related_links', [
      'min-width: 400px;',
      'max-width: 600px;'
    ])}
    {cssRule('#copyright, #privacy a', [
      'color: #2b2b2b;'
    ])}
  </style>;
}

export function VerticalLinesMobile() {
  return <style>
    {commonRules()}
    {cssRule('body', [
      'background-color: #F4F8FC;'
    ])}
    {cssRule('#contentLayout', [
      'width: 100%;'
    ])}
    {cssRule('#sponsored_listings, #related_links', [
      'background-color: #F4F8FC;',
      'border-radius: 0px;',
      'padding-top: 10px;'
    ])}
    {cssRule('#footer', [
      'padding-top: 20px;',
      'width: auto;'
    ])}
    {cssRule('#copyright', [
      'margin: 0px;',
      'color: #2b2b2b;'
    ])}
    {cssRule('#privacy a', [
      'color: #2b2b2b;'
    ])}
    {cssRule('#searchbox', [
      'margin-bottom: 10px;',
      'width: 98%;',
      'font-weight: normal;',
      'text-align: center;',
      'height: 24px;'
    ])}
  </style>;
}

function commonRules() {
  return cssRules([new CssRule(
    'body', [
      'margin: 0;',
      'padding: 0;',
      'font-family: \'Arial\', serif;',
      'align-items: center;',
      'justify-content: center;',
      'display: flex;'
    ]), new CssRule(
    '#tdfsBanner', [
      'background:#00ff00;',
      'font-size: 15px;',
      'padding: .5em;',
      '-webkit-transition: padding 400ms;',
      'transition: padding 400ms;'
    ]), new CssRule(
    '.svg-container', [
      'width:100%;',
      'height:100%;',
      'position: absolute;',
      'z-index: -1;'
    ]), new CssRule(
    '#contentMain', [
      'width:100%;',
      'height:100%;'
    ]), new CssRule(
    '.polygons', [
      'viewBox:0 0 100 100;',
      'preserveAspectRatio:none;',
      'height: 100%;',
      'width: 100%;'
    ]), new CssRule(
    '.polygon1', [
      'fill:#02A4A6'
    ]), new CssRule(
    '.polygon2', [
      'fill:#A6FFF8'
    ]), new CssRule(
    '.polygon3', [
      'fill:#D8EEEF;'
    ]), new CssRule(
    '.polygon4', [
      'fill:#F4F8FC;'
    ]), new CssRule(
    '#tdfsBanner:hover', [
      'padding: .7em .5em;']), new CssRule(
    '#tdfsLink, #tdfsAuctionsLink, #tdfsContactLink, #tdfsCustomForSaleLink', [
      'margin:0 auto;',
      'max-width: 960px;',
      'width: 95%;',
      'text-align: center;']), new CssRule(
    '#tdfsLink a, #tdfsAuctionsLink a, #tdfsContactLink a, #tdfsCustomForSaleLink a', [
      'color: #000;',
      'display: block;',
      'font-size: 1em;',
      'font-weight: 300;',
      'text-decoration: none;',
      'text-shadow:0 1px rgba(0,0,0,.5);']), new CssRule(
    'a', [
      'text-decoration: none;']), new CssRule(
      '#domain', [
      'margin-left:0px;',
      'margin-top:30px;',
      'margin-bottom:30px;',
      'font-size:36px;',
      'text-align: center;',
      'color:#ccc;']), new CssRule(
    '#searchbox', [
      'margin:0 auto;',
      'margin-top: 10px;',
      'margin-bottom: 10px;',
      'width: 50%;',
      'font-weight: normal;',
      'text-align: center;',
      'height: 24px;']), new CssRule(
    '#footer', [
      'width: auto;',
      'padding-top: 70px;']), new CssRule(
    '#headerElement', [
      'padding: 10px;',
      'color: #2b2b2b;',
      'font-size: 11px;',
      'text-align: center;']), new CssRule(
    '.footerLine', [
      'line-height: 25px;',
      'font-size: 11px;',
      'text-align: center;']), new CssRule(
    '.footerColor', [
      'color:#2b2b2b;']), new CssRule(
    '#privacy a', [
      'text-decoration: none;',
      'color:#2b2b2b;']), new CssRule(
    '#loading', [
      'color: white;']), new CssRule(
    '#contentLayout', [
      'margin: auto;']), new CssRule(
    '#sponsored_listings, #related_links', [
      'border-radius: 5px;',
      'padding-top: 1px;']), new CssRule(
    '.highlight', [
      'color: #FD7400;'])
  ]);
}
